import React from 'react';
import { GetContactPageFR } from '../../content/pages/contact/content/en/GetContactPage';
import { GetContactPageEN } from '../../content/pages/contact/content/fr/GetContactPage';
import MailTo from '../utils/mailTo';

const ContactBlock = ({lang}) => {
    const content = lang === 'fr' ? GetContactPageFR() : GetContactPageEN()
    return (
        <div>
            <h2   
                data-sal="slide-up"
                data-sal-delay="300"
                data-sal-easing="ease">{content.contactTitle}</h2>
            <p>{content.contactContent}</p>
            <p>{content.contactSubTitle}</p>
            <hr style={{margin: '30px 0'}}/>
            {
                content.contactAdress.map((e,i)=> (
                    <p key={i}>{e}</p>
                ))
            }
            <MailTo subject="Contact - de-foundation.org">{content.contactEmail}</MailTo>
        </div>
    );
};

export default ContactBlock;