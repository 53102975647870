import React, {useContext} from 'react';
import Layout from '../components/template/layout';
import LangContext from '../components/context/langContext';
import { GetContactPageSeoFR } from '../content/pages/contact/seo/fr/GetContactPageSeo';
import { GetContactPageSeoEN } from '../content/pages/contact/seo/en/GetContactPageSeo';
import ContactBlock from './../components/contact/contactBlock'
import ContactForm from '../components/contact/contactForm';

const Contact = () => {
    const ctx = useContext(LangContext)
    const seoContent = ctx.lang === 'fr' ? GetContactPageSeoFR() : GetContactPageSeoEN()
    return (
        <Layout
            title={seoContent.title}
            description={seoContent.description}
            lang={ctx.lang}
            page="contact"

        >
            <div className="content-container">
                <ContactBlock 
                    lang={ctx.lang}
                /> 
                <ContactForm
                    lang={ctx.lang}
                />
           </div>
        </Layout>
    );
};

export default Contact;